import { red } from "@material-ui/core/colors";
import { Title } from "@material-ui/icons";
import axios, { Axios } from "axios";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ContextMenu } from "primereact/contextmenu";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";


function ManageArticles(props)
{

    const [articles,setArticles] = useState([]);
    const [updateArticles,setUpdateArticles] = useState(0);
    const [selectedArticle, setSelectedArticle] = useState({Title:"",content:"",indexedImage:"",creatorname:""});
    const cm = useRef(null);
    const toast = useRef(null);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const navigate = useNavigate();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showEditPage, setShowEditPage] = useState(false);
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
    const imageBodyTemplate = (rowData) => {
        
        return  <img 
        src={`${process.env.REACT_APP_BACK}/articles/${rowData.indexedImage}`}
        style={{height:"70px",width:"70px", objectFit:'cover'}}>

        </img>
        
        
        
    };
    const regDateBodyTemplate = (rowData) => {
        return new Date(rowData.createdDate).toLocaleDateString('fa-IR');
    };
    
    
    const titleBodyTemplate = (rowData) => {
      const maxLength = 50;
      const content = rowData.title || "";
    
      // Truncate content if it exceeds maxLength
      const truncatedContent = content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
    
      return <div style={{width:'100px',wordWrap: "break-word"}}>{truncatedContent}</div>;
    };

  const metaBodyTemplate = (rowData) => {
    const maxLength = 100;
    const content = rowData.metaDescription || "";
  
    // Truncate content if it exceeds maxLength
    const truncatedContent = content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
  
    return <div style={{width:'150px',wordWrap: "break-word"}}>{truncatedContent}</div>;
  };



const horseBodyTemplate = (rowData) => {
  return  rowData.horseProductsID.length;
};
const riderBodyTemplate = (rowData) => {
  return rowData.riderProductsID.length;
};
const commentsBodyTemplate = (rowData) =>
{
  let c=0;
  if(rowData.comments)
    c=rowData.comments.length;
  
  return c;
};
const visitsBodyTemplate = (rowData) =>
  {
    
    if(rowData.visits)
      return rowData.visits;
    else
    return 0;

    
    
    
  };
  const secondryKeywordsBodyTemplate = (rowData) => {

    let r =  "";
    if(rowData.secondryKeywords)
    {
      rowData.secondryKeywords.forEach(keyword => {
                    const name = Object.keys(keyword)[0];
                    const number_of_links = keyword[name];
                    r += (`${name}:${number_of_links} - `);
                  });
    }
    
      return <div style={{width:'100px'}}>{r}</div>;
}
  const rankBodyTemplate = (rowData) =>
    {
      
      if(rowData.rank!==undefined)
        return (
          <div style={{display:"flex", alignItems:"center"}}>
              {( rowData.rank<10) && 
              (<i className="pi pi-star-fill" 
                style={{background: "green",borderRadius: "15px",width:"30px",height:"30px",padding: "10px",color: "#fff", fontSize:"11px",display:"flex",alignItems:"center"}}>
              </i>)}
          <Button 
            tooltip="آپدیت رتبه گوگل" tooltipOptions={{position:"top"}}
            style={{color: rowData.rank >= 20 ? "red" : "green", fontSize:"20px",cursor:"pointer",background:'transparent',border:"none"}}
            onClick={() => calculateRank(rowData.keyword)}
              >{rowData.rank}</Button>
            </div> );
      else
        return(<Button 
                tooltip="محاسبه رتبه گوگل" tooltipOptions={{position:"top"}}
                style={{color:"red", fontSize:"20px",cursor:"pointer",background:'transparent',border:"none"}}
                onClick={() => calculateRank(rowData.keyword)}
                  >؟</Button> );
      
      
    };

    const calculateRank = async (keyword) =>
    {
      await axios.get(process.env.REACT_APP_BACK+"/articles/calculate_rank/"+keyword, config)
      .then(response => 
        {
          if (response.status == 200)
          {
            if(response.data !=="error")
              toast.current.show({severity: 'info', summary:"رتبه گوگل آپدیت شد" }); 
            else
              toast.current.show({severity: 'error', summary:"مشکلی در آپدیت رتبه گوگل رخ داد" }); 
            setUpdateArticles(updateArticles+1);
            
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });
    }


  const showIconBodyTemplate = (rowData) =>
  {
    return <div >
      <button  
        className="manager-button"
        style={{fontSize:'12px'}}
        onClick={() => window.open("/weblog/" + rowData._id, "_blank")}
    >
      نمایش</button>
      
      </div>
  }


    
    const menuModel = [
        {label: 'ویرایش', icon: 'pi pi-fw pi-pencil', command: () => navigate("/edit-article/", { state: { article: selectedArticle } })},
        {label: 'حذف', icon: 'pi pi-fw pi-trash', command: () => setShowConfirmDelete(true)}
    ];

    // generate sitemap:
    const generateSitemap = async () =>
    {

      
      await axios.get(process.env.REACT_APP_BACK+"/sitemap/generate", config)
      .then(response => 
        {
          if (response.status == 200)
          {
            toast.current.show({severity: 'info', summary: 'نقشه سایت در آدرس زیر ساخته شد: https://flicka.ir/sitemap.xml'}); 
            
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });
    }

    const generateArticles= async () =>
    {
      await axios.get(process.env.REACT_APP_BACK+"/sitemap/create_weblog_articles", config)
      .then(response => 
        {

          
          if (response.status == 200)
          {
            if(response.data!=="empty")
            {
              toast.current.show({severity: 'info', summary: 'مقاله های امروز تولید شد'}); 
              setUpdateArticles(updateArticles+1);
            }
            else
            toast.current.show({severity: 'error', summary: 'فایل متن ورودی خالی است'}); 

            
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });
    }


    const deleteArticleFromDB = async (_id) => 
    {
      await axios.delete(process.env.REACT_APP_BACK+"/articles/" + _id, config)
      .then(response => 
        {
          if (response.status == 200)
          {
            console.log(response);      
            toast.current.show({severity: 'info', summary: 'مقاله حذف شد'});
            setUpdateArticles(updateArticles+1);    
            
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });
    };

   

    //get all articles from database:
    useEffect(() => {
    async function getAllArticles() {

      
      const result = await axios.get(process.env.REACT_APP_BACK+"/articles/all", config)
      .then((result) => {
        if (result.status == 200) {
          setArticles(result.data);
          
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
              navigate("/login")
      });
      return result.data;

    }
    getAllArticles();
  },[updateArticles]);

  
   //initialize formik:
   useEffect(() => {
    async function initForm() {

      editPageForm.values.name = selectedArticle.name;
      editPageForm.values.country = selectedArticle.country;
      editPageForm.values.city = selectedArticle.city;
      editPageForm.values.blueTick = selectedArticle.blueTick;
      

    }
    initForm();
  },[selectedArticle]);

  const editPageForm = useFormik({
    initialValues: {
      name: selectedArticle.name,
      country: selectedArticle.country,
      city: selectedArticle.city,
      blueTick: selectedArticle.blueTick
    },

    onSubmit: (values) => {


      const obj = {};

      for (const key in values) {

        if(key !== "")
        {
          obj[key] = values[key];
        }
        
    }


      const data = JSON.stringify(obj);
 
      
   
    axios.patch(process.env.REACT_APP_BACK+"/pages/update-page/"+selectedArticle._id, data, config)
        .then(response => 
          {

          if(response.status == 200)
          {
            console.log(response);
            setShowEditPage(false);
            setUpdateArticles(updateArticles+1);
      
          }

        })
        .catch(error => {
           console.log(error);
           if(error.response.data.statusCode===401)
              navigate("/login")
        });

        
      
    },
  });

    

    return(

        <div id="manage-pages">

            <h2>
            مدیریت مقاله ها
            </h2>

            <span style={{display:'flex' , paddingRight: desktop ? '50px' : '15px', paddingBottom:'50px'}}>

            <button 
            style={{marginTop:"50px"}} 
            className="login-button"
            onClick={()=>navigate("/new-article")}
            >
                مقاله جدید</button>

               

                <button 
            style={{marginTop:"50px",marginRight:'10px'}} 
            className="login-button"
            onClick={()=>navigate("/weblog")}
            >
                مشاهده وبلاگ</button>

                <button 
            style={{marginTop:"50px",marginRight:'10px'}} 
            className="login-button"
            onClick={generateSitemap}
            >
                ساخت نقشه سایت</button>

                <button 
            style={{marginTop:"50px",marginRight:'10px',background:"#af734f", color:"#ffffff", border: "#af734f 1px solid", borderRadius:'30px', padding:'10px 30px' , cursor:'pointer'}} 
            onClick={generateArticles}
            >
               تولید خودکار مقاله ها</button>
            </span>

            <Toast ref={toast}></Toast>

            <ContextMenu model={menuModel} ref={cm} />

            <DataTable size="normal" value={articles} paginator rows={20} editMode="row"
            className="manager-table"
            contextMenuSelection={selectedArticle}
            onContextMenuSelectionChange={e => setSelectedArticle(e.value)}
            onContextMenu={e => cm.current.show(e.originalEvent)}
            style={{ width: desktop ? '95%' : null}}
            resizableColumns={false} autoLayout={false}
            >
            
            
            <Column alignHeader="center" columnKey="title" align="center" field="title" header="تیتر مقاله" body={titleBodyTemplate}   filter ></Column>
            <Column alignHeader="center" columnKey="keyword" align="center" field="keyword" header="کلمه کلیدی"  style={{width:"20px"}} filter ></Column>
            <Column alignHeader="center" columnKey="secondryKeywords" align="center" field="secondryKeywords" header="کلمات کلیدی فرعی: تعداد ارجاع داخلی"  body={secondryKeywordsBodyTemplate} style={{width:"100px"}} ></Column>
            <Column alignHeader="center" columnKey="image" align="center" field="image" header="عکس شاخص" body={imageBodyTemplate}   ></Column>
            <Column alignHeader="center" columnKey="metaDescription" align="center" field="metaDescription" header="توضیحات متا" body={metaBodyTemplate}  ></Column>
            <Column alignHeader="center" columnKey="horse_pr" align="center" field="horse_pr" header=" محصولات اسب" body={horseBodyTemplate} style={{width:"20px"}} bodyStyle={{width:"20px"}} ></Column>
            <Column alignHeader="center" columnKey="rider_pr" align="center" field="rider_pr" header=" محصولات سوار" body={riderBodyTemplate} style={{width:"20px"}} bodyStyle={{width:"20px"}}></Column>
            <Column alignHeader="center" columnKey="comments" align="center" field="comments" header="تعداد کامنت ها" sortable  body={commentsBodyTemplate} style={{width:"20px"}} bodyStyle={{width:"20px"}}></Column>
            <Column alignHeader="center" columnKey="visits" align="center" field="visits" header="تعداد بازدید ها" sortable  body={visitsBodyTemplate} style={{width:"20px"}} bodyStyle={{width:"20px"}}></Column>
            <Column alignHeader="center" columnKey="rank" align="center" field="rank" header="رتبه گوگل" sortable  body={rankBodyTemplate} style={{width:"20px"}} bodyStyle={{width:"20px"}}></Column>
            <Column alignHeader="center" columnKey="createdDate" align="center" field="createdDate" header="تاریخ ایجاد" body={regDateBodyTemplate} style={{width:"20px"}} sortable></Column>
            <Column alignHeader="center" columnKey="show" align="center" field="show" header="" body={showIconBodyTemplate} ></Column>
            
            
            </DataTable>


            <ConfirmDialog 
            className={props.theme}
            visible={showConfirmDelete} 
            onHide={() => setShowConfirmDelete(false)} 
            message="آیا از حذف این مقاله مطمئنید؟"
            header="تایید حذف"
            icon="pi pi-info-circle"
            acceptLabel="بله"
            rejectLabel="نه"
            acceptClassName="accept"
            rejectClassName="reject"
            accept={() => deleteArticleFromDB(selectedArticle._id)} 
            reject={() => setShowConfirmDelete(false)} />

            <Dialog
            className={props.theme}
                id="edit-user-dialog"
                header="ویرایش صفحه"
                visible={showEditPage}
                onHide={() => setShowEditPage(false)}
                >
            
          <form onSubmit={editPageForm.handleSubmit} className="p-fluid">
          <div className="field">
                <span>
                  <label htmlFor="name">نام صفحه</label>
                  <InputText
                    id="name"
                    name="name"
                    value={editPageForm.values.name}
                    onChange={editPageForm.handleChange}
                  />
                </span>
  
            
            </div>

            <div className="field">
              <label htmlFor="bio">کشور</label>
              <span className=" p-input-icon-left">
                <InputText
                  id="country"
                  name="country"
                  value={editPageForm.values.country}
                  onChange={editPageForm.handleChange}
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="prises">شهر</label>
              <span className=" p-input-icon-left">
                <InputText
                  id="city"
                  name="city"
                  value={editPageForm.values.city}
                  onChange={editPageForm.handleChange}
                  
                />
              </span>
            </div>

            <div className="field">
              <label htmlFor="prises">تیک آبی</label>
              <span className=" p-input-icon-left">
              <InputSwitch
                    id="blueTick"
                    name="blueTick"
                    onChange={editPageForm.handleChange}
                    value={editPageForm.values.blueTick}
                    checked={editPageForm.values.blueTick}

                  />
              
              </span>
            </div>

          
        

          

            <div className="two-columns">
              <div className="field col-one">
                <span>
                  <Button type="submit" label="ذخیره" className="mt-2" />
                </span>
              </div>

              <div className="field col-two">
                <span>
                  <Button
                    className="outline-button mt-2"
                    type="button"
                    label="انصراف"
                    onClick={() => setShowEditPage(false)}
                  />
                </span>
              </div>
            </div>
          </form>
        

        
            </Dialog>

        </div>
    );
}
  
export default ManageArticles;